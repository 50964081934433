<template>
  <div class="base-content">
    <query-frame
      :pageVisible="false"
      :searchVisible="false"
      :addVisible="false"
    >

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
  </div>
</template>

<script>
import { loadCategoryCarData } from '@/api/manage';
import queryMixin from '@/mixin/queryMixin';
import { levelList, levelDelete } from '../../api/level';

export default {
  name: 'LevelList',
  mixins: [queryMixin],
  data() {
    return {
      dictOptions: {},
      dataList: [],
      // 默认展示表格
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        // {
        //   title: '车辆类型',
        //   dataIndex: 'carCategoryId',
        //   key: 'carCategoryId',
        //   ellipsis: true,
        //   width: 200,
        //   customRender: (text) => (text ? filterMultiDictText(this.dictOptions.carCategoryId, text) : ''),
        // },
        {
          title: '等级名称',
          dataIndex: 'level',
          key: 'level',
          ellipsis: true,
        },
        // {
        //   title: '等级排序（升序）',
        //   dataIndex: 'levelOrder',
        //   key: 'levelOrder',
        //   ellipsis: true,
        // },
        {
          title: '最快时间（秒）-时间越短等级越高',
          dataIndex: 'minScore',
          key: 'minScore',
          ellipsis: true,
        },
        // {
        //   title: '最长时间（秒）',
        //   dataIndex: 'minScore',
        //   key: 'minScore',
        //   ellipsis: true,
        // },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 220,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return {
              children:
                <section>
                  <a-button type="link" size="small" onClick={ () => this.handleEdit(row) }>修改</a-button>
                </section>,
            };
          }
        },
      ],
    };
  },
  created() {
    this.handleQuery();
    this.initDictConfig();
  },
  methods: {
    // 获取列表
    getData() {
      this.dataList = [];
      levelList().then((data) => {
        // console.log(data);
        data.forEach((item) => {
          item.key = item.id;
        });
        this.dataList = data;
      });
    },
    // 添加
    handleAdd() {
      this.$router.push({
        name: 'levelAdd'
      });
    },

    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'levelEdit',
        params: {
          id: row.id
        }
      });
    },
    // 删除
    handleDelete(row) {
      const params = {
        ...row
      };
      levelDelete(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
    // 加载车辆类型数据
    initDictConfig() {
      loadCategoryCarData({ code: '0' }).then((res) => {
        // console.log(res)
        if (res !== undefined) {
          this.$set(this.dictOptions, 'carCategoryId', res);
        }
      });
    },
  }
};
</script>

<style scoped>

</style>
